<template>
  <div>
    <cf-data-grid
      ref="detailGrid"
      :options="gridOptions"
      :show-border="true"
    ></cf-data-grid>
  </div>
</template>

<script>
import CloudFun, { defineComponent, reactive, ref } from "@cloudfun/core";
import { DxDataSources } from "../../services/data-source-service";
export default defineComponent({
  components: {},
  props: {
    id: {
      type: Number,
      require: true,
    },
  },
  setup(props) {
    const detailGrid = ref({});
    const defaultDataSourceFilter = ref(["ShoppingCartId", "<", 0]);
    const gridInstance = ref({});

    const generateQueryConditions = (startDate, endDate) => {
      const conditions = [defaultDataSourceFilter.value];
      if (startDate) {
        conditions.push(["Time", ">=", startDate]);
      }
      if (endDate) {
        conditions.push(["Time", "<", endDate]);
      }

      return conditions;
    };

    const ShoppingCartItemDataSource = DxDataSources.ShoppingCartItemDataSource(
      {
        filterExpr: defaultDataSourceFilter.value,
      }
    );

    const gridColumns = [
      {
        dataField: "CreatedTime",
        label: { text: "時間" },

        dataType: "datetime",
        visible: false,
      },
      {
        dataField: "ProductItemSize.ProductItem.Product.Name",
        label: { text: "品名" },
      },
      {
        dataField: "ProductItemSize.ProductItem.Product.Number",
        label: { text: "型號" },
      },
      {
        dataField: "ProductItemSize.Number",
        label: { text: "貨號" },
        sortOrder: "asc",
      },
      { dataField: "ProductItemSize.JANCode", label: { text: "JAN" } },
      {
        dataField: "ProductItemSize.ProductItem.Color.Number",
        label: { text: "色碼" },
      },
      {
        dataField: "ProductItemSize.ProductItem.Color.Name",
        label: { text: "顏色" },
      },
      {
        dataField: "ProductItemSize.Size.Name",
        label: { text: "尺寸" },
      },
      {
        dataField: "PreOrderYear",
        label: { text: "年" },
      },
      {
        dataField: "PreOrderMonth",
        label: { text: "月份" },
      },
      {
        dataField: "Quantity",
        label: { text: "數量" },
        format: { type: "fixedPoint", precision: 0 },
      },
      {
        dataField: "Price",
        label: { text: "採購單價" },
        format: { type: "currency", precision: 0 },
      },
      {
        dataField: "Amount",
        label: { text: "採購小計" },
        format: { type: "currency", precision: 0 },
      },
      //   {
      //     dataField: "Store.Organization.Grade.Name",
      //     label: { text: "經銷商級別" },
      //   },
      //   {
      //     dataField: "Amount",
      //     label: { text: "預定金額" },
      //     format: { type: "currency", precision: 0 },
      //   },
    ];

    const gridOptions = {
      dataSource: ShoppingCartItemDataSource.dataSource,
      canDelete: true,
      canCreate: false,
      height: "auto",
      columns: gridColumns,
      onRowClick: onRowClick,
      focusedEnabled: false,
      remoteOperations: false,
      showFilterPanel: false,
      showGroupPanel: true,
      onInitialized: onGridInitialized,
      summarySettings: {
        items: [
          {
            type: "total",
            column: "Quantity",
            summaryType: "sum",
            displayFormat: "數量: {0}",
            valueFormat: { type: "fixedPoint", precision: 0 },
          },
          {
            type: "total",
            column: "Amount",
            summaryType: "sum",
            skipEmptyValues: true,
            displayFormat: "小計: {0}",
            valueFormat: { type: "currency", precision: 0 },
          },

          {
            type: "group",
            column: "Amount",
            summaryType: "sum",
            skipEmptyValues: true,
            displayFormat: "小计: {0}",
            valueFormat: { type: "currency", precision: 0 },
          },
          {
            type: "group",
            column: "Quantity",
            summaryType: "sum",
            displayFormat: "數量: {0}",
            valueFormat: { type: "fixedPoint", precision: 0 },
          },
        ],
      },
      // onCellPrepared: this.onCellPrepared,
      // onContextMenuPreparing: this.onContextMenuPreparing,
      // stateSave: true,
      // stateStoring: {
      //   enabled: true,
      //   storageKey: this.$route.name,
      // },
    };

    function onRowClick({ key }) {
      // this.loadData(key);
      console.log("key", key);
    }

    function onGridInitialized(e) {
      gridInstance.value = e.component;
      console.log("e", gridInstance);
    }

    function reload(id) {
      defaultDataSourceFilter.value = [];

      if (id > 0) {
        defaultDataSourceFilter.value.push(["ShoppingCartId", "=", id]);
        gridOptions.dataSource.filter(defaultDataSourceFilter.value);
        gridOptions.dataSource.load();
      }
    }

    return {
      defaultDataSourceFilter,
      generateQueryConditions,
      ShoppingCartItemDataSource,
      gridOptions,
      onRowClick,
      detailGrid,
      gridInstance,
      reload,
    };
  },
  watch: {
    id(newValue, oldValue) {
      // console.log("newValue", newValue);
      // console.log("grid", this.gridInstance);
      // this.ShoppingCartItemDataSource.load().then((resp) =>
      //   console.log("watch resp", resp)
      // );
      //   if (newValue > 0) this.gridInstance.refresh(true);
    },
  },
  methods: {},
});
</script>

<style scoped></style>
